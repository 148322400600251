import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import ResetPasswordComponent from './resetPasswordComponent';
import { resetPassword } from '../actions';
import { error, success } from '../../feedback/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  dispatchResetPassword: (username, token, password) => {
    dispatch(resetPassword(username, token, password));
  },
  dispatchError: message => {
    dispatch(error(message));
  },
  dispatchSuccess: () => {
    dispatch(success());
  }
});

class ResetPassword extends Component {
  checkPassword = (password, passwordconfirm) => {
    const { dispatchSuccess, dispatchError } = this.props;
    if (this.passwordMatch(password, passwordconfirm)) {
      dispatchSuccess();
    } else {
      dispatchError('De paswoorden zijn niet gelijk.');
    }
  };

  handleSubmit = (password, passwordconfirm) => {
    const { location, dispatchResetPassword, dispatchSuccess, dispatchError } = this.props;
    if (this.passwordMatch(password, passwordconfirm)) {
      const urlValues = queryString.parse(location.search);
      dispatchSuccess();
      dispatchResetPassword(urlValues.username, urlValues.token, password);
    } else {
      dispatchError('De paswoorden zijn niet gelijk.');
    }
  };

  passwordMatch = (password, passwordconfirm) => {
    if (password === '' || passwordconfirm === '') {
      return true;
    }
    return password === passwordconfirm;
  };

  render() {
    return <ResetPasswordComponent handleSubmit={this.handleSubmit} checkPassword={this.checkPassword} />;
  }
}

ResetPassword.propTypes = {
  location: PropTypes.object,
  dispatchError: PropTypes.func.isRequired,
  dispatchSuccess: PropTypes.func.isRequired,
  dispatchResetPassword: PropTypes.func.isRequired
};

ResetPassword.defaultProps = {
  location: {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
