export default {
  button: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  headerContainer: {
    display: 'flex',
    minHeight: '64px',
    paddingLeft: '24px',
    paddingRight: '8px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  tableContainer: {
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
  tableSpacer: {
    padding: '14px 40px 14px 25px'
  },
  userActionIcons: {
    color: 'black'
  }
};
