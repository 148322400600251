import * as types from './actiontypes';

export const error = message => {
  return {
    type: types.ERROR,
    payload: message
  };
};

export const success = message => {
  return {
    type: types.SUCCESS,
    payload: message
  };
};
