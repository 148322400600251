import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';

import Drawer from '@material-ui/core/Drawer';

import roles from '../../Common/role';
import NavBarLink from './navbarLink';

import { drawerStyles, listItemStyle } from './navbarStyle';

class NavbarDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, login, logOut, handleDrawerClose, open } = this.props;
    return (
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          <div className={classes.toolbar}>
            <div className={classes.drawerHeader}>
              <IconButton color="inherit">
                <Avatar src="/images/logo.png" component={Link} to="/" />
              </IconButton>
              <Typography variant="h6" color="inherit" noWrap>
                Dxter
              </Typography>
            </div>
            <IconButton onClick={handleDrawerClose}>
              <Icon>chevron_left</Icon>
            </IconButton>
          </div>
          <Divider />
          <List>
            <NavBarLink login={login} to="/" text="Home" iconName="home" />
            <NavBarLink login={login} roles={[roles.HR]} to="/user/list" text="Gebruikers beheren" iconName="supervisor_account" />
            <ListItem button onClick={logOut}>
              <ListItemIcon className={classes.iconStyle}>
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.root }}>Log uit</ListItemText>
            </ListItem>
          </List>
        </Drawer>
      </div>
    );
  }
}

NavbarDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  login: PropTypes.object.isRequired
};

export default withStyles(theme => ({ ...drawerStyles(theme), ...listItemStyle(theme) }), { withTheme: true })(NavbarDrawer);
