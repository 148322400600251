import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import { Toolbar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';

import { appBarStyles } from './navbarStyle';

class NavbarAppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, open, handleDrawerOpen } = this.props;
    return (
      <div>
        <AppBar position="fixed" className={classNames(classes.appBar, { [classes.appBarShift]: open })}>
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: open
              })}
            >
              <Icon>menu</Icon>
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

NavbarAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default withStyles(appBarStyles, { withTheme: true })(NavbarAppBar);
