import * as types from './actiontypes';

export default function(state = { type: null, message: null }, action) {
  switch (action.type) {
    case types.SUCCESS:
      return { ...state, type: 'SUCCESS', message: action.payload };
    case types.ERROR:
      return { ...state, type: 'ERROR', message: action.payload };
    default:
      return state;
  }
}
