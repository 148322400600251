import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import CounterTableComponent from './counterTableComponent';
import { fetchUser, createCounter } from '../actions';
import { counterSelector } from '../selectors';

const mapStateToProps = (state, ownProps) => ({
  counters: counterSelector(state, ownProps.match.params.id)
});

const mapDispatchToProps = dispatch => ({
  fetchUserDetail: id => dispatch(fetchUser(id)),
  addCounter: (userId, counter) => dispatch(createCounter(userId, counter))
});

class CounterTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameError: false,
      startError: false,
      endError: false,
      totalError: false
    };
  }

  componentDidMount() {
    const { fetchUserDetail, match } = this.props;
    fetchUserDetail(match.params.id);
  }

  deleteRow = () => {};

  validateFields = fields => {
    this.setState({ nameError: false, startError: false, endError: false, totalError: false });
    if (fields.name && fields.startDate && fields.endDate && fields.totalDays) {
      return true;
    }
    if (!fields.name) {
      this.setState({ nameError: true });
    }
    if (!fields.startDate) {
      this.setState({ startError: true });
    }
    if (!fields.endDate) {
      this.setState({ endError: true });
    }
    if (!fields.totalDays) {
      this.setState({ totalError: true });
    }
    return false;
  };

  addRow = counter => {
    const { addCounter, match } = this.props;
    addCounter(match.params.id, counter);
  };

  render() {
    const { nameError, endError, startError, totalError } = this.state;
    const { counters } = this.props;
    return <CounterTableComponent nameError={nameError} startError={startError} totalError={totalError} endError={endError} counters={counters} validateFields={this.validateFields} deleteRow={this.deleteRow} addRow={this.addRow} />;
  }
}

CounterTable.propTypes = {
  fetchUserDetail: PropTypes.func.isRequired,
  addCounter: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  counters: PropTypes.array
};
CounterTable.defaultProps = {
  counters: []
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CounterTable)
);
