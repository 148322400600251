import { connect } from 'react-redux';
import { logOutUser } from '../user/actions';
import { loginSelector } from '../login/selectors';
import NavbarComponent from './navbarComponent';

const mapStateToProps = state => ({
  login: loginSelector(state)
});

const mapDispatchToProps = dispatch => ({
  logOut: () => {
    dispatch(logOutUser());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarComponent);
