import jwtdecode from 'jwt-decode';
import * as types from './actiontypes';
import { post } from '../../Services/ApiService';
import history from '../../Common/history';
import * as loaderActions from '../loader/actions';
import { success } from '../feedback/actions';

export const logout = () => {
  return {
    type: types.LOGOUT
  };
};

export const loginSuccess = response => {
  return dispatch => {
    dispatch(success());
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: response
    });
  };
};

export const login = (username, password) => {
  return dispatch => {
    dispatch(loaderActions.loadingstart());
    post(dispatch, 'user/login', { username, password })
      .then(response => {
        const token = response.data.payload;
        localStorage.setItem('APIToken', token);
        const user = jwtdecode(token);
        dispatch(loginSuccess({ role: user.role, name: user.unique_name, id: user.nameid }));
        history.push('/');
      })
      .catch(() => {});
  };
};
