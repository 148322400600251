import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import UserCounterTable from '../user/counter/userCounterTable';

const styles = () => ({});

const HomeComponent = () => {
  return <UserCounterTable />;
};

export default withStyles(styles)(HomeComponent);
