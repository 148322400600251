import { combineReducers } from 'redux';
import login from './App/Components/login/reducer';
import feedback from './App/Components/feedback/reducer';
import loader from './App/Components/loader/reducer';
import user from './App/Components/user/reducer';

const rootReducer = combineReducers({
  login,
  feedback,
  loader,
  user
});

export default rootReducer;
