import * as types from './actiontypes';

export default function(state = { loading: false }, action) {
  switch (action.type) {
    case types.LOADING_START:
      return { ...state, loading: true };
    case types.LOADING_STOP:
      return { ...state, loading: false };
    default:
      return state;
  }
}
