export default {
  body: {
    emptyDataSourceMessage: 'Er zijn nog geen tellers aangemaakt.',
    addTooltip: 'Teller toevoegen',
    deleteToolTip: 'Verwijderen',
    editToolTip: 'Aanpassen',
    editRow: {
      deleteText: 'Ben je zeker dat je deze teller wilt verwijderen?',
      cancelTooltip: 'Annuleren',
      saveTooltip: 'Opslaan'
    }
  },
  pagination: {
    labelRowsSelect: 'rijen',
    labelRowsPerPage: 'Rijen per pagina:',
    firstAriaLabel: 'Eerste pagina',
    firstTooltip: 'Eerste pagina',
    previousAriaLabel: 'Vorige pagina',
    previousTooltip: 'Vorige pagina',
    nextAriaLabel: 'Volgende pagina',
    nextTooltip: 'Volgende pagina',
    lastAriaLabel: 'Laatste pagina',
    lastTooltip: 'Laatste pagina',
    labelDisplayedRows: '{from}-{to} van {count}'
  }
};
