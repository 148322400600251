import * as types from './actiontypes';

export default function(state = { users: [] }, action) {
  switch (action.type) {
    case types.USER_LIST_SUCCESS:
      return { ...state, users: action.payload };
    case types.USER_GET_SUCCESS: {
      const newState = state;
      let index = state.users.findIndex(el => el.id === action.payload.id);
      index = Math.max(index, 0);
      newState.users[index] = action.payload;
      return { ...newState };
    }
    default:
      return state;
  }
}
