import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { DatePicker } from '@material-ui/pickers';
import { Input } from '@material-ui/core';
import counterTableLocalization from './counterTableLocalization';
import counterTableOptions from './counterTableOptions';
import Feedback from '../../feedback/feedback';

const CounterTableComponent = props => {
  const { counters, deleteRow, addRow, validateFields, nameError, startError, endError, totalError } = props;
  const headerColumns = [
    { title: 'Naam', field: 'name', cellStyle: { padding: '14px 40px 14px 25px' }, editComponent: fieldProps => <Input placeholder="Naam" error={nameError} required value={fieldProps.value} onChange={e => fieldProps.onChange(e.target.value)} /> },
    {
      title: 'Startdatum',
      field: 'startDate',
      type: 'date',
      cellStyle: { padding: '14px 40px 14px 25px' },
      editComponent: tableProps => (
        <DatePicker type="text" required autoOk disablePast minDate={new Date().getDate()} format="DD/MM/YYYY" error={startError} defaultValue={tableProps.value} cancelLabel="Annuleer" onChange={date => tableProps.onChange(date)} />
      )
    },
    {
      title: 'Einddatum',
      field: 'endDate',
      type: 'date',
      cellStyle: { padding: '14px 40px 14px 25px' },
      editComponent: tableProps => <DatePicker autoOk required disablePast cancelLabel="Annuleer" minDate={new Date().getDate()} error={endError} format="DD/MM/YYYY" value={tableProps.value} onChange={date => tableProps.onChange(date)} />
    },
    {
      title: 'Saldo',
      field: 'totalDays',
      type: 'numeric',
      cellStyle: { padding: '14px 38px 14px 25px' },
      editComponent: fieldProps => <Input placeholder="Saldo" required type="number" defaultValue={fieldProps.value} error={totalError} onChange={e => fieldProps.onChange(e.target.value)} />
    }
  ];

  return (
    <>
      <Feedback />
      <MaterialTable
        title="Tellers beheren"
        columns={headerColumns}
        data={counters}
        options={counterTableOptions}
        localization={counterTableLocalization}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              if (validateFields(newData)) {
                addRow(newData);
                resolve();
              } else {
                reject();
              }
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const data = [...counters];
                data.splice(data.indexOf(oldData), 1);
                deleteRow();
              }, 600);
            })
        }}
      />
    </>
  );
};

CounterTableComponent.propTypes = {
  counters: PropTypes.array,
  deleteRow: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
  totalError: PropTypes.bool.isRequired,
  startError: PropTypes.bool.isRequired,
  endError: PropTypes.bool.isRequired,
  nameError: PropTypes.bool.isRequired
};

CounterTableComponent.defaultProps = {
  counters: []
};

export default CounterTableComponent;
