/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Loader from '../../loader/loader';
import Feedback from '../../feedback/feedback';

const styles = theme => ({
  container: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light
  }
});

class LoginComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    const { username, password } = this.state;
    const { onLogin } = this.props;
    onLogin(username, password);
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Loader />
        <Paper className={classes.container} elevation={0}>
          <Avatar className={classes.avatar} src="/images/logo.png" />
          <Typography component="h4" variant="h5">
            Welkom bij Dxter
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <Feedback />
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Emailadres</InputLabel>
              <Input onChange={this.handleChange} id="username" name="username" autoComplete="username" autoFocus />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Paswoord</InputLabel>
              <Input onChange={this.handleChange} name="password" type="password" id="password" autoComplete="current-password" />
            </FormControl>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Inloggen
            </Button>
          </form>
        </Paper>
      </>
    );
  }
}

LoginComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onLogin: PropTypes.func.isRequired
};

export default withStyles(styles)(LoginComponent);
