import { connect } from 'react-redux';
import { login } from '../actions';
import LoginComponent from './loginComponent';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  onLogin: (username, password) => {
    dispatch(login(username, password));
  }
});

const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);

export default Login;
