import * as moment from 'moment';
import { createSelector } from 'reselect';
import { loginIdSelector } from '../login/selectors';

export const usersSelector = state => state.user.users;
export const fullName = user => {
  return `${user.firstname} ${user.name}`;
};

const formatCounters = user => {
  if (user === undefined) {
    return [];
  }
  return user.counters.map(item => {
    return {
      ...item,
      startDate: moment(item.startDate).format('D/M/YYYY'),
      endDate: moment(item.endDate).format('D/M/YYYY')
    };
  });
};

export const counterSelector = (state, id) => {
  const user = state.user.users.find(x => x.id === id);
  return formatCounters(user);
};

export const ownCounterSelector = state => {
  const user = state.user.users.find(x => x.id === loginIdSelector(state));
  if (user !== undefined) {
    return user.counters.map(item => {
      return {
        ...item,
        startDate: moment(item.startDate),
        endDate: moment(item.endDate)
      };
    });
  }
  return [];
};

export const ownActiveCounterSelector = createSelector(
  ownCounterSelector,
  counters => {
    const filteredCounters = counters.filter(counter => {
      return counter.endDate > moment(new Date());
    });
    return filteredCounters.map(item => {
      return {
        ...item,
        startDate: item.startDate.format('D/M/YYYY'),
        endDate: item.endDate.format('D/M/YYYY')
      };
    });
  }
);
