import * as types from './actiontypes';

export const loadingstart = () => {
  return {
    type: types.LOADING_START
  };
};

export const loadingstop = () => {
  return {
    type: types.LOADING_STOP
  };
};
