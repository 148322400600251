import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchUsers } from '../actions';
import { usersSelector } from '../selectors';
import UserListComponent from './userListComponent';

const mapStateToProps = state => ({
  users: usersSelector(state)
});

const mapDispatchToProps = dispatch => ({
  fetchUserList: () => {
    dispatch(fetchUsers());
  }
});

class UserList extends Component {
  componentDidMount() {
    const { fetchUserList } = this.props;
    fetchUserList();
  }

  render() {
    return <UserListComponent {...this.props} />;
  }
}

UserList.propTypes = {
  fetchUserList: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserList);
