import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Feedback from '../../feedback/feedback';
import Loader from '../../loader/loader';

const styles = theme => ({
  container: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light
  }
});

class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordconfirm: ''
    };
  }

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value }, () => {
      const { password, passwordconfirm } = this.state;
      const { checkPassword } = this.props;
      checkPassword(password, passwordconfirm);
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { password, passwordconfirm } = this.state;
    const { handleSubmit } = this.props;
    handleSubmit(password, passwordconfirm);
  };

  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.container}>
        <Avatar className={classes.avatar} src="/images/logo.png" />
        <Typography component="h4" variant="h5">
          Kies je nieuwe paswoord:
        </Typography>
        <Loader />
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Wachtwoord</InputLabel>
          <Input onChange={this.handleChange} id="password" name="password" type="password" autoComplete="password" autoFocus />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="passwordConfirm">Bevestig wachtwoord</InputLabel>
          <Input onChange={this.handleChange} name="passwordconfirm" id="passwordconfirm" type="password" autoComplete="passwordconfirm" />
        </FormControl>
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submitButton}>
          Wijzig wachtwoord
        </Button>
        <Feedback />
      </form>
    );
  }
}

ResetPasswordComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  checkPassword: PropTypes.func.isRequired
};

export default withStyles(styles)(ResetPasswordComponent);
