import axios from 'axios';
import history from '../Common/history';
import { OK } from '../Common/status';
import { success, error } from '../Components/feedback/actions';
import { loadingstart, loadingstop } from '../Components/loader/actions';

const apiAxios = axios.create();

apiAxios.interceptors.request.use(config => {
  const interceptedConfig = config;
  if (localStorage.getItem('APIToken')) {
    interceptedConfig.headers = { Authorization: `bearer ${localStorage.getItem('APIToken')}` };
  }
  return interceptedConfig;
});

apiAxios.interceptors.response.use(
  response => {
    return response;
  },
  errorResponse => {
    if (errorResponse.response === undefined) {
      return Promise.reject(errorResponse);
    }
    if (errorResponse.response.status === 401) {
      history.push('/login');
      return Promise.reject(errorResponse);
    }
    return Promise.reject(errorResponse);
  }
);

const composeUrl = endpoint => {
  return process.env.REACT_APP_API_URL + endpoint;
};

export const post = (dispatch, endpoint, data) => {
  const promise = new Promise((resolve, reject) => {
    dispatch(loadingstart());
    apiAxios
      .post(composeUrl(endpoint), data)
      .then(response => {
        dispatch(loadingstop());
        if (response.data.status === OK) {
          dispatch(success);
          resolve(response);
        } else {
          dispatch(error(response.data.payload));
          reject();
        }
      })
      .catch(() => {
        dispatch(loadingstop());
        dispatch(error('Er is iets misgegaan.'));
        reject();
      });
  });
  return promise;
};

export const get = (dispatch, endpoint) => {
  const promise = new Promise((resolve, reject) => {
    dispatch(loadingstart());
    apiAxios
      .get(composeUrl(endpoint))
      .then(response => {
        dispatch(loadingstop());
        if (response.data.status === OK) {
          dispatch(success);
          resolve(response);
        } else {
          dispatch(error(response.data.payload));
          reject();
        }
      })
      .catch(() => {
        dispatch(loadingstop());
        dispatch(error('Er is iets misgegaan'));
        reject();
      });
  });
  return promise;
};
