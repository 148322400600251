import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#63abcc',
      light: '#fafafa',
      dark: '#2f287b'
    },
    secondary: {
      main: '#2f287b',
      light: '',
      dark: ''
    }
  },
  typography: {
    useNextVariants: true
  }
});
