import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';
import Loader from '../../loader/loader';
import userListStyle from './userListStyle';

const UserListComponent = props => {
  const { users, classes } = props;
  return (
    <>
      <div className={classes.tableContainer}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>Gebruikers beheren</Typography>
          <IconButton className={classes.button} component={Link} to="/user/create" aria-label="Delete">
            <Icon>add_box</Icon>
          </IconButton>
        </div>
        <Loader />
        <Table size="medium">
          <TableHead>
            <TableRow key="head">
              <TableCell className={classes.tableSpacer}>Gebruiker</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.id}>
                <TableCell className={classes.tableSpacer}>
                  {user.username}
                  <br />
                  {`${user.firstname} ${user.name}`}
                </TableCell>
                <TableCell>
                  <Icon component={Link} to={`/user/${user.id}/counters`} className={classes.userActionIcons}>
                    date_range
                  </Icon>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

UserListComponent.propTypes = {
  users: PropTypes.array,
  classes: PropTypes.object.isRequired
};

UserListComponent.defaultProps = {
  users: []
};

export default withStyles(userListStyle)(UserListComponent);
