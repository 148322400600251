import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { isLoadingSelector } from './selectors';

const Loader = props => {
  const { loading } = props;
  return <>{loading ? <LinearProgress /> : null}</>;
};

const mapStateToProps = state => ({
  loading: isLoadingSelector(state)
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loader);

Loader.propTypes = {
  loading: PropTypes.bool.isRequired
};
