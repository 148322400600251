import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as log from 'loglevel';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    log.error(error);
    log.info(info);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <h1>Oops, something went wrong</h1>;
    }
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.array.isRequired
};

export default ErrorBoundary;
