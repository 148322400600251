import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core';
import { listItemStyle } from './navbarStyle';

const NavBarLink = props => {
  const { login, roles, to, text, classes, iconName } = props;

  if (roles.length !== 0 && !roles.includes(login.role)) {
    return null;
  }

  return (
    <>
      <ListItem button to={to} component={Link}>
        <ListItemIcon className={classes.iconStyle}>
          <Icon>{iconName}</Icon>
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.root }}>{text}</ListItemText>
      </ListItem>
    </>
  );
};

NavBarLink.propTypes = {
  login: PropTypes.object.isRequired,
  roles: PropTypes.array,
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  iconName: PropTypes.string.isRequired
};

NavBarLink.defaultProps = {
  roles: []
};

export default withStyles(listItemStyle)(NavBarLink);
