import React from 'react';
import { Route } from 'react-router-dom';
import { withRouter, Switch } from 'react-router';
import PropTypes from 'prop-types';
import PrivateRoute from '../privateroute/privateroute';
import ResetPassword from './resetPassword/resetPassword';
import UserCreate from './create/userCreate';
import CounterTable from './counter/counterTable';
import UserList from './list/userList';
import roles from '../../Common/role';

const User = props => {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.path}/reset`} component={ResetPassword} />
      <PrivateRoute path={`${match.path}/create`} component={UserCreate} roles={[roles.HR]} />
      <PrivateRoute path={`${match.path}/:id/counters`} component={CounterTable} roles={[roles.HR]} />
      <PrivateRoute path={`${match.path}/list`} component={UserList} roles={[roles.HR]} />
    </Switch>
  );
};

User.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(User);
