import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import UserCounterTableComponent from './userCounterTableComponent';
import { fetchUser } from '../actions';
import { ownActiveCounterSelector } from '../selectors';
import { loginSelector } from '../../login/selectors';

const mapStateToProps = state => ({
  login: loginSelector(state),
  counters: ownActiveCounterSelector(state)
});

const mapDispatchToProps = dispatch => ({
  fetchUserDetail: id => dispatch(fetchUser(id))
});

class UserCounterTable extends Component {
  componentDidMount() {
    const { fetchUserDetail, login } = this.props;
    fetchUserDetail(login.id);
  }

  render() {
    const { counters } = this.props;
    return <UserCounterTableComponent counters={counters} />;
  }
}

UserCounterTable.propTypes = {
  fetchUserDetail: PropTypes.func.isRequired,
  counters: PropTypes.array,
  login: PropTypes.object.isRequired
};
UserCounterTable.defaultProps = {
  counters: []
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserCounterTable)
);
