import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Switch, Route, Router } from 'react-router-dom';

import './App.css';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/nl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalTheme from './Common/theme';

import PrivateRoute from './Components/privateroute/privateroute';
import ErrorBoundary from './Components/errorBoundary/errorBoundary';
import Home from './Components/home/home';
import Login from './Components/login/authenticate/login';
import User from './Components/user/user';
import history from './Common/history';

const App = () => {
  return (
    <MuiThemeProvider theme={GlobalTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="nl" libInstance={moment}>
        <ErrorBoundary>
          <CssBaseline />
          <Router history={history}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/user" component={User} />
              <PrivateRoute path="/" component={Home} />
            </Switch>
          </Router>
        </ErrorBoundary>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
