import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { feedbackTypeSelector, feedbackMessageSelector } from './selectors';

const mapStateToProps = state => ({
  type: feedbackTypeSelector(state),
  message: feedbackMessageSelector(state)
});

const mapDispatchToProps = () => ({});

function Feedback(props) {
  const { type, message } = props;
  if (message === '') {
    return null;
  }

  if (type === 'SUCCESS' && message !== '') {
    return (
      <Typography color="textPrimary" component="p" variant="body2" gutterBottom>
        {message}
      </Typography>
    );
  }

  if (type === 'ERROR' && message !== '') {
    return (
      <Typography color="error" component="p" variant="body2" gutterBottom>
        {message}
      </Typography>
    );
  }

  return null;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedback);

Feedback.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string
};

Feedback.defaultProps = {
  type: null,
  message: ''
};
