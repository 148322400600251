import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import jwtdecode from 'jwt-decode';
import rootReducer from './rootReducer';

const loadUserFromLocalstorage = () => {
  const token = localStorage.getItem('APIToken');

  const defaultStore = {
    feedback: { type: null, message: null },
    loader: { loading: false },
    login: { login: null }
  };

  if (token !== null) {
    try {
      const user = jwtdecode(token);
      defaultStore.login = {
        login: {
          role: user.role,
          name: user.unique_name,
          id: user.nameid
        }
      };
    } catch (e) {
      localStorage.removeItem('APIToken');
    }
    return defaultStore;
  }
  return defaultStore;
};

export default function configureStore() {
  const middlewares = [thunkMiddleware];

  const composeEnhancers = composeWithDevTools({ trace: true });

  const store = createStore(rootReducer, loadUserFromLocalstorage(), composeEnhancers(applyMiddleware(...middlewares)));

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
  }

  return store;
}
