import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Navbar from '../navbar/navbar';
import { loginSelector } from '../login/selectors';

const mapStateToProps = state => ({
  login: loginSelector(state)
});

const mapDispatchToProps = () => ({});

const styles = () => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    margin: '95px 30px 30px 30px'
  }
});

const privateroute = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={() => {
      const { roles, login, classes } = props;
      if (login === null)
        return (
          <Redirect
            to={{
              pathname: '/login'
            }}
          />
        );

      if (roles.length !== 0 && !roles.includes(login.role)) {
        return (
          <Redirect
            to={{
              pathname: '/'
            }}
          />
        );
      }

      return (
        <>
          <div className={classes.root}>
            <nav>
              <Navbar />
            </nav>
            <main className={classes.content}>
              <Component />
            </main>
          </div>
        </>
      );
    }}
  />
);

privateroute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
  login: PropTypes.object.isRequired,
  roles: PropTypes.array,
  classes: PropTypes.object.isRequired
};

privateroute.defaultProps = {
  location: {},
  roles: []
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(privateroute));
