import { createSelector } from 'reselect';

export const loginSelector = state => state.login.login;
export const loginIdSelector = createSelector(
  loginSelector,
  selector => {
    return selector.id;
  }
);
export { loginSelector as default };
