/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import Loader from '../../loader/loader';
import Feedback from '../../feedback/feedback';

const styles = () => ({});

class UserComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      firstname: '',
      username: '',
      role: ''
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name, firstname, username, role } = this.state;
    const { createUser } = this.props;
    createUser(name, firstname, username, role);
  };

  render() {
    const { classes } = this.props;
    const { role } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Loader />
        <Feedback />
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="firstname">Voornaam</InputLabel>
          <Input onChange={this.handleChange} id="firstname" name="firstname" autoComplete="firstname" autoFocus />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="name">Naam</InputLabel>
          <Input onChange={this.handleChange} name="name" id="name" autoComplete="name" />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="username">Emailadres / Gebruikersnaam</InputLabel>
          <Input onChange={this.handleChange} id="username" type="email" name="username" autoComplete="email" />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="rol">Rol</InputLabel>
          <Select value={role} onChange={this.handleChange} input={<Input name="role" id="rol" required />}>
            <MenuItem value="User">Gebruiker</MenuItem>
            <MenuItem value="Hr">HR</MenuItem>
          </Select>
        </FormControl>
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Maak gebruiker aan
        </Button>
      </form>
    );
  }
}

UserComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  createUser: PropTypes.func.isRequired
};

export default withStyles(styles)(UserComponent);
