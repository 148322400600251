import { post, get } from '../../Services/ApiService';
import history from '../../Common/history';
import * as types from './actiontypes';
import { success } from '../feedback/actions';
import { logout } from '../login/actions';

export const logOutUser = () => {
  return dispatch => {
    localStorage.removeItem('APIToken');
    dispatch(logout());
    history.push('/login');
  };
};

export const resetPassword = (username, token, password) => {
  return dispatch => {
    post(dispatch, 'user/reset', { username, token, password })
      .then(() => {
        dispatch(logOutUser());
      })
      .catch(() => {});
  };
};

export const createUser = (name, firstname, username, role) => {
  return dispatch => {
    post(dispatch, 'user/create', { name, firstname, username, role })
      .then(() => {
        dispatch(success('De gebruiker is correct aangemaakt.'));
      })
      .catch(() => {});
  };
};

export const userListSuccess = users => {
  return {
    type: types.USER_LIST_SUCCESS,
    payload: users
  };
};

export const userGetSuccess = user => {
  return {
    type: types.USER_GET_SUCCESS,
    payload: user
  };
};

export const fetchUsers = () => {
  return dispatch => {
    get(dispatch, 'user')
      .then(response => {
        dispatch(userListSuccess(response.data.payload));
      })
      .catch(() => {});
  };
};

export const fetchUser = id => {
  return dispatch => {
    get(dispatch, `user/${id}`)
      .then(response => {
        dispatch(userGetSuccess(response.data.payload));
      })
      .catch(() => {});
  };
};

export const createCounter = (userId, countermodel) => {
  return dispatch => {
    post(dispatch, 'counter/create', { countermodel, userId })
      .then(() => {
        dispatch(fetchUser(userId));
      })
      .catch(() => {});
  };
};
