/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';

import App from './App/App';
import configureStore from './configureStore';

const store = configureStore();

// eslint-disable-next-line no-undef
const rootElement = document.getElementById('root');
const renderApp = () =>
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App/App', renderApp);
}

renderApp();
