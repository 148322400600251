import * as types from './actiontypes';

export default function(state = { login: { role: null, name: null, id: null } }, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return { ...state, login: { role: action.payload.role, name: action.payload.name, id: action.payload.id } };
    case types.LOGOUT:
      return { ...state, login: { login: null } };
    default:
      return state;
  }
}
