import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import NavbarAppBar from './navbarAppBar';
import NavbarDrawer from './navbarDrawer';

const styles = () => ({});

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, login, logOut } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.root}>
        <NavbarAppBar handleDrawerOpen={this.handleDrawerOpen} open={open} />
        <NavbarDrawer login={login} logOut={logOut} handleDrawerClose={this.handleDrawerClose} open={open} />
      </div>
    );
  }
}

NavbarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  login: PropTypes.object.isRequired
};

export default withStyles(styles)(NavbarComponent);
