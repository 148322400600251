import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import userCounterTableStyle from './userCounterTableStyle';
import Loader from '../../loader/loader';

const UserCounterTableComponent = props => {
  const { counters, classes } = props;
  return (
    <>
      <div className={classes.tableContainer}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>Tellers</Typography>
        </div>
        <Loader />
        <Table size="medium">
          <TableHead>
            <TableRow key="head">
              <TableCell className={classes.tableSpacer}>Naam</TableCell>
              <TableCell className={classes.tableSpacer}>Startdatum</TableCell>
              <TableCell className={classes.tableSpacer}>Einddatum</TableCell>
              <TableCell className={classes.tableSpacer}>Saldo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {counters.map(counter => (
              <TableRow key={counter.id}>
                <TableCell className={classes.tableSpacer}>{counter.name}</TableCell>
                <TableCell className={classes.tableSpacer}>{counter.startDate}</TableCell>
                <TableCell className={classes.tableSpacer}>{counter.endDate}</TableCell>
                <TableCell className={classes.tableSpacer}>{counter.totalDays}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

UserCounterTableComponent.propTypes = {
  counters: PropTypes.array,
  classes: PropTypes.object.isRequired
};

UserCounterTableComponent.defaultProps = {
  counters: []
};

export default withStyles(userCounterTableStyle)(UserCounterTableComponent);
