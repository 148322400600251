import { connect } from 'react-redux';
import { createUser } from '../actions';
import UserCreateComponent from './userCreateComponent';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  createUser: (name, firstname, username, role) => {
    dispatch(createUser(name, firstname, username, role === 'User' ? null : role));
  }
});

const UserCreate = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCreateComponent);

export default UserCreate;
